import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwk keyset add`}</strong>{` -- a JWK to a JWK Set`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwk keyset add <jwks-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwk keyset add`}</strong>{` reads a JWK from STDIN and adds it to the JWK
Set in `}<inlineCode parentName="p">{`jwks-file`}</inlineCode>{`. Modifications to `}<inlineCode parentName="p">{`jwks-file`}</inlineCode>{` are in-place. The file is
'flock'd while it's being read and modified.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`jwks-file`}</inlineCode>{`
File containing a JWK Set`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      